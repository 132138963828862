import axios from "axios";
import store from '../store/index'
import ElementUI from "element-ui";
import router from "../router/index";
axios.defaults.withCredentials = true; //true允许跨域
if (process.env.NODE_ENV === "production") {
  //production 生产环境
  // axios.defaults.baseURL = "https://xinyouju.ysxrj.cn";
  axios.defaults.baseURL = "https://xin.xinyoujudichan.com";
  // axios.defaults.baseURL = "http://localhost:8090/index.php"
} else {
  //dev 开发环境
  // axios.defaults.baseURL = "/api";
  // axios.defaults.baseURL = "https://xinyouju.ysxrj.cn";
  axios.defaults.baseURL = "https://xin.xinyoujudichan.com";
  // axios.defaults.baseURL = "http://localhost:8090/index.php"
}

// 添加请求拦截器
axios.interceptors.request.use((config) => {
  // 在发送请求之前做些什么
  config.headers['token'] = store.getters.token;
  return config;
}, (error) => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器

axios.interceptors.response.use((response) => {
  // 对响应数据做点什么
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    if (response.data.code === 1) {
      return resolve(response.data);
    } else if (response.data.code == -401) {
      if (location.hash !== '#/login') {
        router.push({ path: '/login' });
      }
    } else {
      if (response.data.msg != '请听取录音' || response.data.msg) {
        ElementUI.Message.warning(response.data.msg)
      }
      return reject(response.data);

    }
  });


}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default axios