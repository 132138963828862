import Vue from "vue";
import './common/directives'; // 弹窗拖拽
import http from './axios/api'
import ElementUI from "element-ui";
import BaiduMap from 'vue-baidu-map'
import "element-ui/lib/theme-chalk/index.css";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

import index from './common/index'

import filters from '@/common/filters.js'
Vue.prototype.$filter = filters

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'dRPvN4edk5uIRyW5nLDtB1CG9dE8s4dl'
})

import App from "./App.vue";
import router from "./router";
import store from "./store";
Vue.mixin(index);
// Vue.prototype.$echarts = echarts;

Vue.prototype.$axios = http
Vue.prototype.$url = ''
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
