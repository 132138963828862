// 时间搓
const to_date_time = (phpstr) => {
  const str = parseInt(phpstr) * 1000// 将时间戳转化为整形并乘以1000
  const newDate = new Date(str)
  const year = newDate.getUTCFullYear()// 取年份
  const month = newDate.getUTCMonth() + 1// 取月份
  const nowday = newDate.getUTCDate()// 取天数
  // const hours = newDate.getHours()// 取小时
  // const minutes = newDate.getMinutes()// 取分钟
  // const seconds = newDate.getSeconds()// 取秒
  return year + '-' + (month < 10 ? '0' + month : month) + '-' + (nowday < 10 ? '0' + nowday : nowday)// 拼接 2017-2-21
}
export default {
  to_date_time
}
