import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [

  //首页
  {
    path: "/",
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ "../views/tabbar/home.vue"),
    meta: {
      index: '0'
    }
  },
  //淘宝池
  {
    path: "/taobao",
    name: 'Taobao',
    component: () => import(/* webpackChunkName: "about" */ "../views/tabbar/taobao.vue"),
    meta: {
      index: '1'
    }
  },
  //房源管理
  {
    path: "/housing",
    name: 'Housing',
    component: () => import(/* webpackChunkName: "about" */ "../views/tabbar/housing.vue"),
    meta: {
      index: '2',
      keepAlive: true
    }
  },
  {
    path: '/housingDetails',
    name: 'HousingDetails',
    component: () => import('../views/housing/HousingDetails.vue'),
    meta: {
      index: '2'
    },
  },
  {
    path: '/houseSign',
    name: 'HouseSign',
    component: () => import("../views/housing/houseSign.vue"),
    meta: {
      index: '7',
      type: '1-2'
    }
  },
  //客源管理
  {
    path: "/passenger",
    name: 'Passenger',
    component: () => import(/* webpackChunkName: "about" */ "../views/tabbar/passenger.vue"),
    meta: {
      index: '3',
      keepAlive: true
    }
  },
  //微课堂
  {
    path: "/microlecture",
    name: 'Microlecture',
    component: () => import(/* webpackChunkName: "about" */ "../views/tabbar/microlecture.vue"),
    meta: {
      index: '4'
    }
  },
  //人才库
  {
    path: "/talents",
    name: 'Talents',
    component: () => import(/* webpackChunkName: "about" */ "../views/tabbar/talents.vue"),
    meta: {
      index: '5'
    }
  },
  //业绩统计
  {
    path: "/performance",
    name: 'Performances',
    component: () => import(/* webpackChunkName: "about" */ "../views/tabbar/performance.vue"),
    meta: {
      index: '6'
    }
  },
  // 新增客户
  {
    path: "/perBuyer",
    name: 'PerBuyer',
    component: () => import(/* webpackChunkName: "about" */ "../views/performance/perBuyer.vue"),
    meta: {
      index: '6'
    }
  },
  // 新增客户
  {
    path: "/perHouse",
    name: 'PerHouse',
    component: () => import(/* webpackChunkName: "about" */ "../views/performance/perHouse.vue"),
    meta: {
      index: '6'
    }
  },
  // 新增带看
  {
    path: "/perLook",
    name: 'PerLook',
    component: () => import(/* webpackChunkName: "about" */ "../views/performance/perLook.vue"),
    meta: {
      index: '6'
    }
  },
  // 新增跟进
  {
    path: "/perIn",
    name: 'PerIn',
    component: () => import(/* webpackChunkName: "about" */ "../views/performance/perIn.vue"),
    meta: {
      index: '6'
    }
  },
  // 新增信息方
  {
    path: "/perParty",
    name: 'PerParty',
    component: () => import(/* webpackChunkName: "about" */ "../views/performance/perParty.vue"),
    meta: {
      index: '6'
    }
  },
  //8个人中心-4业绩目标-2查看业绩-1新增房源
  {
    path: "/addHouse",
    name: 'AddHouse',
    component: () => import(/* webpackChunkName: "about" */ "../views/personage/AddHouse"),
    meta: {
      index: '7'
    }
  },
  //8个人中心-4业绩目标-2查看业绩-1新增客户
  {
    path: "/addTourist",
    name: 'AddTourist',
    component: () => import(/* webpackChunkName: "about" */ "../views/personage/AddTourist"),
    meta: {
      index: '7'
    }
  },
  //8个人中心-4业绩目标-2查看业绩-1新增带看
  {
    path: "/addLook",
    name: 'AddLook',
    component: () => import(/* webpackChunkName: "about" */ "../views/personage/AddLook.vue"),
    meta: {
      index: '7'
    }
  },
  //8个人中心-4业绩目标-2查看业绩-1新增带看
  {
    path: "/addFollow",
    name: 'AddFollow',
    component: () => import(/* webpackChunkName: "about" */ "../views/personage/AddFollow.vue"),
    meta: {
      index: '7'
    }
  },
  //8个人中心-4业绩目标-2查看业绩-1新增各个方
  {
    path: "/addSide",
    name: 'AddSide',
    component: () => import(/* webpackChunkName: "about" */ "../views/personage/AddSide.vue"),
    meta: {
      index: '7'
    }
  },
  //8个人中心-4业绩目标-查看客源
  {
    path: "/sourceCustomer",
    name: 'SourceCustomer',
    component: () => import(/* webpackChunkName: "about" */ "../views/personage/SourceCustomer.vue"),
    meta: {
      index: '7'
    }
  },
  //8个人中心-4业绩目标-2查看业绩-5业绩-1线上
  {
    path: "/achievementOn",
    name: 'AchievementOn',
    component: () => import(/* webpackChunkName: "about" */ "../views/personage/AchievementOn"),
    meta: {
      index: '7'
    }
  },
  //8个人中心-4业绩目标-2查看业绩-5业绩-2线下
  {
    path: "/achievementOff",
    name: 'AchievementOff',
    component: () => import(/* webpackChunkName: "about" */ "../views/personage/AchievementOff"),
    meta: {
      index: '7'
    }
  },
  //8个人中心-4业绩目标-2查看业绩-3新增约看
  {
    path: "/achievementAppo",
    name: 'AchievementAppo',
    component: () => import(/* webpackChunkName: "about" */ "../views/personage/AchievementAppo"),
    meta: {
      index: '7'
    }
  },
  //8个人中心-4业绩目标-2查看业绩-2新增带看-1客源
  {
    path: "/achievementT",
    name: 'AchievementT',
    component: () => import(/* webpackChunkName: "about" */ "../views/personage/AchievementT"),
    meta: {
      index: '7'
    }
  },
  //8个人中心-4业绩目标-2查看业绩-4新增跟进-1客源
  {
    path: "/achievementFollow",
    name: 'AchievementFollow',
    component: () => import(/* webpackChunkName: "about" */ "../views/personage/AchievementFollow"),
    meta: {
      index: '7'
    }
  },
  //8个人中心-4业绩目标-2查看业绩-4新增跟进-2房源-2楼栋号
  {
    path: "/achievementFollowH",
    name: 'AchievementFollowH',
    component: () => import(/* webpackChunkName: "about" */ "../views/personage/AchievementFollowH"),
    meta: {
      index: '7'
    }
  },
  //8个人中心-4业绩目标-2查看业绩-4新增跟进-2房源-1手机号
  {
    path: "/achievementFollowM",
    name: 'AchievementFollowM',
    component: () => import(/* webpackChunkName: "about" */ "../views/personage/AchievementFollowM"),
    meta: {
      index: '7'
    }
  },
  //个人中心
  {
    path: "/personage",
    name: 'Personage',
    component: () => import(/* webpackChunkName: "about" */ "../views/tabbar/personage.vue"),
    meta: {
      index: '7'
    },
    children: [
      //8个人中心-3交易记录-1新房
      {
        path: 'onSign',
        name: 'OnSign',
        component: () => import("../views/personage/OnSign"),
        meta: {
          index: '7',
          type: '2-1'
        }
      },
      //8个人中心-3交易记录-3线下签约
      {
        path: 'offSign',
        name: 'OffSign',
        component: () => import("../views/personage/OffSign"),
        meta: {
          index: '7',
          type: '2-2'
        }
      },
      //8个人中心-3交易记录--详情
      {
        path: 'signDetail',
        name: 'SignDetail',
        component: () => import("../views/personage/SignDetail"),
        meta: {
          index: '7',
          type: '2-1'
        }
      },
      {
        path: 'offSignDetails',
        name: 'OffSignDetails',
        component: () => import("../views/personage/OffSignDetails"),
        meta: {
          index: '7',
          type: '2-1'
        }
      },
      //钥匙管理
      //完善率
      {
        path: 'keyRate',
        name: 'KeyRate',
        component: () => import("../views/personage/KeyRate.vue"),
        meta: {
          index: '7',
          type: '16-1'
        }
      },
      //借还记录
      {
        path: 'keyRecord',
        name: 'KeyRecord',
        component: () => import("../views/personage/KeyRecord.vue"),
        meta: {
          index: '7',
          type: '16-2'
        }
      },
      //钥匙管理借还记录（经理）
      {
        path: 'keyReturn',
        name: 'KeyReturn',
        component: () => import("../views/personage/KeyReturn"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //钥匙管理其他人
      {
        path: 'keyOther',
        name: 'KeyOther',
        component: () => import("../views/personage/KeyOther"),
        meta: {
          index: '7',
          type: ''
        }
      },

      //合同管理
      //合同领取
      {
        path: 'agreeDraw',
        name: 'AgreeDraw',
        component: () => import("../views/personage/AgreeDraw")
      },
      //我的合同上传
      {
        path: 'agreeUpload',
        name: 'AgreeUpload',
        component: () => import("../views/personage/AgreeUpload"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //合同上传
      {
        path: 'agreeCheck',
        name: 'AgreeCheck',
        component: () => import("../views/personage/AgreeCheck"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //合同详情
      {
        path: 'agreeDetail',
        name: 'AgreeDetail',
        component: () => import("../views/personage/AgreeDetail"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //团队成员-总监
      {
        path: 'team',
        name: 'Team',
        component: () => import("../views/personage/Team"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //团队成员-店长
      {
        path: 'teamShop',
        name: 'TeamShop',
        component: () => import("../views/personage/TeamShop"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //团队成员-店长
      {
        path: 'teamManager',
        name: 'TeamManager',
        component: () => import("../views/personage/TeamManager"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //团队成员-经纪人
      {
        path: 'teamClerk',
        name: 'TeamClerk',
        component: () => import("../views/personage/TeamClerk"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //人事管理
      //员工管理
      {
        path: 'affairsStaff',
        name: 'AffairsStaff',
        component: () => import("../views/personage/AffairsStaff"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //员工管理-新增
      {
        path: 'affairsStaffAdd',
        name: 'AffairsStaffAdd',
        component: () => import("../views/personage/AffairsStaffAdd"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //员工管理-详情
      {
        path: 'affairsStaffDetail',
        name: 'AffairsStaffDetail',
        component: () => import("../views/personage/AffairsStaffDetail"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //设置
      //长期锁定记录
      {
        path: 'setLong',
        name: 'SetLong',
        component: () => import("../views/personage/SetLong"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //长期锁定记录住宅出售
      {
        path: 'setSale',
        name: 'SetSale',
        component: () => import("../views/personage/SetSale"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //长期锁定记录住宅出租
      {
        path: 'setLease',
        name: 'SetLease',
        component: () => import("../views/personage/SetLease"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //房源变更
      {
        path: 'setState',
        name: 'SetState',
        component: () => import("../views/personage/SetState"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //修改密码
      {
        path: 'setPassword',
        name: 'SetPassword',
        component: () => import("../views/personage/SetPassword"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //员工法则
      {
        path: 'setRule',
        name: 'SetRule',
        component: () => import("../views/personage/SetRule"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //关于我们
      {
        path: 'setAbout',
        name: 'SetAbout',
        component: () => import("../views/personage/SetAbout"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //消息
      {
        path: 'information',
        name: 'Information',
        component: () => import("../views/personage/Information"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //黑名单
      {
        path: 'blackList',
        name: 'BlackList',
        component: () => import("../views/personage/BlackList"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //我的关注
      {
        path: 'myAttention',
        name: 'MyAttention',
        component: () => import("../views/personage/MyAttention"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //我的关注-出售
      {
        path: 'myAttentionSale',
        name: 'MyAttentionSale',
        component: () => import("../views/personage/MyAttentionSale"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //我的关注-出租
      {
        path: 'myAttentionLease',
        name: 'MyAttentionLease',
        component: () => import("../views/personage/MyAttentionLease"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //我的佣金
      {
        path: 'myCommission',
        name: 'MyCommission',
        component: () => import("../views/personage/MyCommission"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //我的违规
      {
        path: 'individual',
        name: 'Individual',
        component: () => import("../views/personage/Individual"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //我的违规-记录
      {
        path: 'individualDetail',
        name: 'IndividualDetail',
        component: () => import("../views/personage/IndividualDetail"),
        meta: {
          index: '7',
          type: ''
        }
      },
      //我的违规-其他
      //荣誉
      {
        path: 'honor',
        name: 'Honor',
        component: () => import("../views/personage/Honor.vue"),
        meta: {
          index: '7',
          type: '6'
        }
      },
      //荣誉-获奖
      {
        path: 'honorAward',
        name: 'HonorAward',
        component: () => import("../views/personage/HonorAward"),
        meta: {
          index: '7',
          type: '6'
        }
      },
      //荣誉-获奖-记录
      {
        path: 'awardDetail',
        name: 'AwardDetail',
        component: () => import("../views/personage/AwardDetail"),
        meta: {
          index: '7',
          type: '6'
        }
      },
      //考勤
      {
        path: 'attendance',
        name: 'Attendance',
        component: () => import("../views/personage/Attendance"),
        meta: {
          index: '7',
          type: '5'
        }
      },
      //审核状态
      //合同审核
      {
        path: 'auditMent',
        name: 'AuditMent',
        component: () => import("../views/personage/AuditMent"),
        meta: {
          index: '7',
          type: '4'
        }
      },
      //成交报告-审核中-我的
      {
        path: 'auditReport',
        name: 'AuditReport',
        component: () => import("../views/personage/AuditReport"),
        meta: {
          index: '7',
          type: '4-2'
        }
      },
      //报告审核详情
      {
        path: 'reportDetail',
        name: 'ReportDetail',
        component: () => import("../views/personage/ReportDetail"),
        meta: {
          index: '7',
          type: '4-2'
        }
      },
      //信息变更
      {
        path: 'auditMessage',
        name: 'AuditMessage',
        component: () => import("../views/personage/AuditMessage"),
        meta: {
          index: '7',
          type: '4-2'
        }
      },
      //信息变更-详情
      {
        path: 'messageDetail',
        name: 'MessageDetail',
        component: () => import("../views/personage/MessageDetail"),
        meta: {
          index: '7',
          type: '4-2'
        }
      },
      //请假审核-审核中
      {
        path: 'auditLeave',
        name: 'AuditLeave',
        component: () => import("../views/personage/AuditLeave"),
        meta: {
          index: '7',
          type: '4-2'
        }
      },
      //晋升审核
      {
        path: 'auditPromote',
        name: 'AuditPromote',
        component: () => import("../views/personage/AuditPromote"),
        meta: {
          index: '7',
          type: '4-2'
        }
      },
      //报销审核
      {
        path: 'auditExp',
        name: 'AuditExp',
        component: () => import("../views/personage/AuditExp"),
        meta: {
          index: '7',
          type: '4-8'
        }
      },
      //添加小区-审核
      {
        path: 'auditAdd',
        name: 'AuditAdd',
        component: () => import("../views/personage/AuditAdd"),
        meta: {
          index: '7',
          type: '4-8'
        }
      },
      //删除审核-房源-审核
      {
        path: 'auditDH',
        name: 'AuditDH',
        component: () => import("../views/personage/AuditDH"),
        meta: {
          index: '7',
          type: '4-4'
        }
      },
      //业绩目标
      {
        path: 'achieve',
        name: 'Achieve',
        component: () => import("../views/personage/Achieve"),
        meta: {
          index: '7',
          type: '3'
        }
      },
      //新增钥匙方
      {
        path: 'key',
        name: 'Key',
        component: () => import('../views/personage/Achieve/key'),
        meta: {
          index: '7',
          type: '3'
        }
      },
      //新增信息
      {
        path: 'information',
        name: 'Informations',
        component: () => import('../views/personage/Achieve/information'),
        meta: {
          index: '7',
          type: '3'
        }
      },
      //新增维护方
      {
        path: 'maintain',
        name: 'Maintain',
        component: () => import('../views/personage/Achieve/maintain'),
        meta: {
          index: '7',
          type: '3'
        }
      },
      //新增图片方
      {
        path: 'picture',
        name: 'Picture',
        component: () => import('../views/personage/Achieve/picture'),
        meta: {
          index: '7',
          type: '3'
        }
      },
      //新增委托方
      {
        path: 'entrust',
        name: 'Entrust',
        component: () => import('../views/personage/Achieve/entrust'),
        meta: {
          index: '7',
          type: '3'
        }
      },
      //业绩目标-下级
      {
        path: 'achieveS',
        name: 'AchieveS',
        component: () => import("../views/personage/AchieveS"),
        meta: {
          index: '7',
          type: '3'
        }
      },
      //业绩目标-经理
      {
        path: 'achieveM',
        name: 'AchieveM',
        component: () => import("../views/personage/AchieveM"),
        meta: {
          index: '7',
          type: '3'
        }
      },
      //业绩目标-经纪人
      {
        path: 'achieveA',
        name: 'AchieveA',
        component: () => import("../views/personage/AchieveA"),
        meta: {
          index: '7',
          type: '3'
        }
      },

      //约看
      {
        path: 'aboutSee',
        name: 'AboutSee',
        component: () => import("../views/personage/AboutSee"),
        meta: {
          index: '7'
        }
      },
      //客源
      {
        path: 'customer',
        name: 'Customer',
        component: () => import("../views/personage/Customer.vue"),
        meta: {
          index: '7',
          type: '1-1'
        }
      },

      //房源
      {
        path: 'housing',
        name: 'Housings',
        component: () => import("../views/personage/Housing.vue"),
        meta: {
          index: '7',
          type: '1-2'
        }
      },
      //业绩目标
      {
        path: 'performance',
        name: 'Performance',
        component: () => import("../views/personage/Performance.vue"),
        meta: {
          index: '7',
          type: '2-2'
        }
      },
      //人事管理
      {
        path: 'staff',
        name: 'Staff',
        component: () => import("../views/personage/Staff.vue"),
        meta: {
          index: '7',
          type: '15-1'
        }
      },
      //员工详情
      {
        path: 'staffDetails',
        name: 'StaffDetails',
        component: () => import("../views/personage/StaffDetails.vue"),
        meta: {
          index: '7',
          type: '15-1'
        }
      },
      //新增员工
      {
        path: 'addStaff',
        name: 'AddStaff',
        component: () => import("../views/personage/AddStaff.vue"),
        meta: {
          index: '7',
          type: '15-1'
        }
      },
      {
        path: 'interview',
        name: 'Interview',
        component: () => import("../views/personage/Interview.vue"),
        meta: {
          index: '7',
          type: '15-2'
        }
      }
    ]
  },
  // 小区
  {
    path: "/estate",
    name: "Estate",
    component: () => import("../views/housing/estate.vue"),
    meta: {
      index: '2'
    }
  },
  // 小区详情
  {
    path: "/estateDetail",
    name: "EstateDetail",
    component: () => import("../views/housing/estateDetail.vue"),
    meta: {
      index: '2'
    }
  },
  // 小区统计
  {
    path: "/estateTotal",
    name: "EstateTotal",
    component: () => import("../views/housing/estateTotal.vue"),
    meta: {
      index: '2'
    }
  },
  // 编辑小区
  {
    path: "/estateEdit",
    name: "EstateEdit",
    component: () => import("../views/housing/estateEdit.vue"),
    meta: {
      index: '2'
    }
  },
  // 添加客源
  {
    path: "/addPassenger",
    name: "AddPassenger",
    component: () => import("../views/home/AddPassenger.vue"),
    meta: {
      index: '0'
    }
  },
  // 编辑客源
  {
    path: "/editPassenger",
    name: "EditPassenger",
    component: () => import("../views/home/EditPassenger.vue"),
    meta: {
      index: '3'
    }
  },
  // 客源详情
  {
    path: "/passengerDetails",
    name: "PassengerDetails",
    component: () => import("../views/passenger/PassengerDetails.vue"),
    meta: {
      index: '3'
    }
  },
  //忘记密码
  {
    path: "/forgetPassword",
    name: 'ForgetPassword',
    component: () => import(/* webpackChunkName: "about" */ "../views/login/ForgetPassword.vue"),
    meta: {
      index: '7'
    }
  },
  //业务规范
  {
    path: "/business",
    name: 'Business',
    component: () => import(/* webpackChunkName: "about" */ "../views/home/Business.vue"),
    meta: {
      index: '0'
    }
  },
  //大事件
  {
    path: "/bigIncident",
    name: 'BigIncident',
    component: () => import(/* webpackChunkName: "about" */ "../views/home/BigIncident.vue"),
    meta: {
      index: '0'
    }
  },
  //详情
  {
    path: "/details",
    name: 'Details',
    component: () => import("../views/home/Details.vue"),
    meta: {
      index: '0'
    }
  },
  //登录
  {
    path: "/login",
    name: 'Login',
    component: () => import("../views/login/Login.vue")
  },
  //微课堂 课程
  {
    path: "/courseDetails",
    name: 'CourseDetails',
    component: () => import("../views/microlecture/courseDetails.vue"),
    meta: {
      index: '4'
    }
  },
  //微课堂 视频详情
  {
    path: "/message",
    name: 'Message',
    component: () => import("../views/microlecture/message.vue")
  },
  //微课堂 视频详情
  {
    path: "/video",
    name: 'Videos',
    component: () => import("../views/microlecture/Video.vue")
  },
  //微课堂 考试
  {
    path: "/exam",
    name: 'Exam',
    component: () => import("../views/microlecture/Exam.vue"),
    meta: {
      index: '4'
    }
  },
  //微课堂 考试结果
  {
    path: "/examResult",
    name: 'ExamResult',
    component: () => import("../views/microlecture/ExamResult.vue"),
    meta: {
      index: '4'
    }
  },
  //微课堂 往期考试记录
  {
    path: "/examRecord",
    name: 'ExamRecord',
    component: () => import("../views/microlecture/ExamRecord.vue")
  },
  //微课堂 试题解析
  {
    path: "/analysis",
    name: 'Analysis',
    component: () => import("../views/microlecture/Analysis.vue"),
    meta: {
      index: '4'
    }
  },
  //微课堂 下属成绩
  {
    path: "/subordinateGrade",
    name: 'SubordinateGrade',
    component: () => import("../views/microlecture/SubordinateGrade.vue"),
    meta: {
      index: '4'
    }
  },
  //首页 添加房源
  {
    path: "/addHousing",
    name: 'AddHousing',
    component: () => import("../views/home/AddHousing.vue"),
    meta: {
      index: '0'
    }
  },
  {
    path: "/addRentHousing",
    name: 'AddRentHousing',
    component: () => import("../views/home/AddRentHousing.vue"),
    meta: {
      index: '0'
    }
  },

  //首页 编辑房源
  {
    path: "/editHousing",
    name: 'EditHousing',
    component: () => import("../views/home/EditHousing.vue"),
    meta: {
      index: '2'
    }
  },
  {
    path: "/editRentHousing",
    name: 'EditRentHousing',
    component: () => import("../views/home/EditRentHousing.vue"),
    meta: {
      index: '2'
    }
  },
  //首页 带看管理
  {
    path: '/bespectacled',
    name: 'Bespectacled',
    component: () => import("../views/home/Bespectacled.vue"),
    meta: {
      index: '0'
    }
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 } //期望滚动到哪个的位置
    }
  },

});

export default router;
