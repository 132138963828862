<template>
  <div id="app">
    <Header v-if="urlShow"></Header>
    <keep-alive> 
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <Footer v-if="urlShow"></Footer>
  </div>
</template>
<script>
import Footer from './components/Footer.vue';
import Header from './components/Header.vue';
// eslint-disable-next-line no-unused-vars
import MapLoader from './unti/map'
import index from './common/index'
// import BMap from 'BMap'
export default {
  mixins: [index],
  components: { Header, Footer },
  data() {
    return {
      urlShow:false
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        this.urlShow = newVal.path == "/forgetPassword" || newVal.path == '/login' ? false : true;
      },
      //  immediate为true时则立即触发回调函数；如果为false，则和上面的例子一样，不会立即执行回调。
      immediate: true,
      deep: true,
    },
  },
  methods:{
    getLocation() {
        var geolocation = new BMap.Geolocation();

        geolocation.getCurrentPosition((data) => {
          this.$store.commit('SET_CITY',data.address)
          this.$store.commit('SET_LOCATION',{lng:data.longitude,lat:data.latitude})
          this.$axios.location({location:data.latitude+','+ data.longitude}).then(res=>{
            let location = res.data.result.addressComponent
            this.$store.commit('SET_REGION',location.province + '/' + location.city + '/' + location.district)
            
          })
        });
    },
  },
  created () {
    this.getUserInfo()
    // this.getLocation()
    !localStorage.getItem("token") ? this.$router.push({ path: '/login' }) : ''
  }
};
</script>

<style lang="less">
.el-popper {
  z-index: 2000007 !important;
}
@import "common/index.less";

</style>
