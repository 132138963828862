import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      housingType:[
        {
          value: "/housing?type=2",
          label: "房源",
        },
        {
          value: "/estate?type=1",
          label: "找小区",
        },
      ],
      whetherList:[
        {
          value: '',
          label: '不限'
        },
        {
          value: "是",
          label: "是",
        },
        {
          value: "否",
          label: "否",
        }
      ],//是否列表
      whethersList:[
        {
          value: 2,
          label: "是",
        },
        {
          value: 1,
          label: "否",
        }
      ],
      sexList: [
        {
          value: "男",
          label: "男",
        },
        {
          value: "女",
          label: "女",
        },
      ],//性别列表
      housingTypeList: [
        {
          value: 1,
          label: "新房",
        },
        {
          value: 2,
          label: "住宅",
        },
        {
          value: 3,
          label: "公寓",
        },
        {
          value: 4,
          label: "写字楼",
        },
        {
          value: 5,
          label: "商铺",
        },
        {
          value: 6,
          label: "别墅",
        },
      ],//房源类型
      zuHousingTypeList: [
        {
          value: -1,
          label: "不限"
        },
        {
          value: 2,
          label: "住宅"
        },
        {
          value: 3,
          label: "公寓"
        },
        {
          value: 4,
          label: "写字楼"
        },
        {
          value: 5,
          label: "商铺"
        },
        {
          value: 6,
          label: "别墅"
        }
      ],//房源类型
      priceList: [
        {
          value: "1",
          label: "50万以下"
        },
        {
          value: "2",
          label: "50-100万"
        },
        {
          value: "3",
          label: "100-150万"
        },
        {
          value: "4",
          label: "150-200万"
        },
        {
          value: "5",
          label: "200-300万"
        },
        {
          value: "6",
          label: "300-500万"
        }
      ],//房源类型
      fitmentList: [
        {
          value: "",
          label: "不限",
        },
        {
          value: "豪华装修",
          label: "豪华装修",
        },
        {
          value: "精装修",
          label: "精装修",
        },
        {
          value: "简单装修",
          label: "简单装修",
        },
        {
          value: "毛坯",
          label: "毛坯",
        }
      ],//装修列表
      positionList: [
        {
          value: "1",
          label: "区域总监",
        },
        {
          value: "2",
          label: "店长",
        },
        {
          value: "3",
          label: "经理",
        },
        {
          value: "4",
          label: "经纪人",
        }
      ],//职位列表
      wayList:[
        {
          value: "整租",
          label: "整租",
        },
        {
          value: "合租",
          label: "合租",
        }
      ],//租住方式
      waysList:[
        {
          value: "短租",
          label: "短租",
        },
        {
          value: "长租",
          label: "长租",
        }
      ],
      purposeHouseTypeList:[
        {
          value: "1室",
          label: "1室",
        },
        {
          value: "2室",
          label: "2室",
        },
        {
          value: "3室",
          label: "3室",
        },
        {
          value: "4室",
          label: "4室",
        },
        {
          value: "5室",
          label: "5室",
        }
      ],
      roomList:[
        {
          value: 10000,
          label: "标间"
        },
        {
          value: 1,
          label: "1居"
        },
        {
          value: 2,
          label: "2居"
        },
        {
          value: 3,
          label: "3居"
        },
        {
          value: 4,
          label: "4居"
        },
        {
          value: 5,
          label: "5居"
        },
        {
          value: 6,
          label: "5居以上"
        }
      ],//几室
      payList:[
        {
          value: "",
          label: "不限",
        },
        {
          value: "月付",
          label: "月付",
        },
        {
          value: "季付",
          label: "季付",
        },
        {
          value: "半年付",
          label: "半年付",
        },
        {
          value: "年付",
          label: "年付",
        }
      ],//付钱方式
      leaseList:[
        {
          value: "",
          label: "不限"
        },
        {
          value: "1-3月",
          label: "1-3月"
        },
        {
          value: "4-6月",
          label: "4-6月"
        },
        {
          value: "7-9月",
          label: "7-9月"
        },
        {
          value: "10-12月",
          label: "10-12月"
        },
        {
          value: "1年起租",
          label: "1年起租",
        },
        {
          value: "1年以上",
          label: "1年以上",
        }
      ],//租期
      openingList:[
        {
          value: "0-1",
          label: "1个月",
        },
        {
          value: "2-3",
          label: "2-3个月",
        },
        {
          value: "4-6",
          label: "4-6个月",
        },
        {
          value: "6-12",
          label: "6-12个月",
        },
        {
          value: "12-12",
          label: "1年",
        },
        {
          value: "24-24",
          label: "2年",
        },
        {
          value: "36-36",
          label: "3年",
        },
        {
          value: "36-120",
          label: "3年以上",
        }
      ],//租期
      zuList:[
        {
          value: "1个月",
          label: "地下室",
        },
        {
          value: "2-3个月",
          label: "1层",
        },
        {
          value: "4-6个月",
          label: "顶层",
        }
      ],//租期
      saiList: [
        {
          name: '实勘',
          options: ['实勘1', '实勘2', '实勘3']
        },
        {
          name: '我的角色',
          options: ['我的角色1', '我的角色2', '我的角色3']
        },
        {
          name: '房屋用途',
          options: ['房屋用途1', '房屋用途2', '房屋用途3']
        },
        {
          name: '装修',
          options: ['装修1', '装修2', '装修3']
        },
        {
          name: '建筑结构',
          options: ['建筑结构1', '建筑结构2', '建筑结构3']
        },
        {
          name: '房龄',
          options: ['房龄1', '房龄2', '房龄3']
        },
        {
          name: '装修时间',
          options: ['装修时间1', '装修时间2', '装修时间3']
        },
        {
          name: '看房方式',
          options: ['看房方式1', '看房方式2', '看房方式3']
        },
        {
          name: '最早入住时间',
          options: ['最早入住时间1', '最早入住时间2', '最早入住时间3']
        },
        {
          name: '上新时间',
          options: ['上新时间1', '上新时间2', '上新时间3']
        },
      ],
      classList:[
        {
          value: 1,
          label: "展位"
        },
        {
          value: 2,
          label: "A"
        },
        {
          value: 3,
          label: "B"
        },
        {
          value: 4,
          label: "C"
        },
        {
          value: 5,
          label: "D"
        }
      ],//等级列表
      areaList:[
        {
          value: "1",
          label: "50m²以下",
        },
        {
          value: "2",
          label: "50-70/m²",
        },
        {
          value: "3",
          label: "70-90/m²",
        },
        {
          value: "4",
          label: "90-110/m²",
        },
        {
          value: "5",
          label: "110-140/m²",
        },
        {
          value: "5",
          label: "140m²以上",
        }
      ],//等级列表
      orientationList:[
        {
          value: '',
          label: "不限"
        },
        {
          value: "东",
          label: "东"
        },
        {
          value: "东南",
          label: "东南"
        },
        {
          value: "南",
          label: "南"
        },
        {
          value: "西",
          label: "西"
        },
        {
          value: "西北",
          label: "西北"
        },
        {
          value: "西南",
          label: "西南"
        },
        {
          value: "北",
          label: "北"
        },
        {
          value: "东北",
          label: "东北"
        },
        {
          value: "南北",
          label: "南北"
        },
        {
          value: "东西",
          label: "东西"
        }
      ],
      panTime:[
        {
          value: "首开",
          label: "首开",
        },
        {
          value: "加推",
          label: "加推",
        }
      ],//开盘次数
      typeList:[
        {
          value: '1',
          label: "求租",
        },
        {
          value: '2',
          label: "求购",
        }
      ],//客户类型
      numList:[
        {
          value: 1,
          label: "首开",
        },
        {
          value: 2,
          label: "加推",
        }
      ],//客户类型
      clientType:[
        {
          value: 1,
          label: "私客源"
        },
        {
          value: 2,
          label: "公客源"
        }
      ],
      statusList:[
        {
          value: 1,
          label: "未交易"
        },
        {
          value: 3,
          label: "有效暂缓"
        },
        {
          value: 5,
          label: "无效暂缓"
        },
        {
          value: 7,
          label: "我司成交"
        },
        {
          value: 9,
          label: "他司成交"
        }
      ],//状态类型
      roleList:[
        {
          value: "",
          label: "不限",
        },
        {
          value: "1",
          label: "信息方",
        },
        {
          value: "2",
          label: "维护方",
        },
        {
          value: "3",
          label: "图片方",
        },
        {
          value: "4",
          label: "钥匙方",
        },
        {
          value: "5",
          label: "委托方",
        },
      ],//角色列表
      houseUse:[
        {
          value: "",
          label: "不限",
        },
        {
          value: "居住",
          label: "居住",
        },
        {
          value: "办公",
          label: "办公",
        },
        {
          value: "商住两用",
          label: "商住两用",
        }
      ],//房屋用途
      lookWayList:[
        {
          value: '',
          label: "不限"
        },
        {
          value: "有钥匙",
          label: "有钥匙"
        },
        {
          value: "有密码",
          label: "有密码"
        },
        {
          value: "借钥匙",
          label: "借钥匙"
        },
        {
          value: "临时密码",
          label: "临时密码"
        },
        {
          value: "业主开门",
          label: "业主开门"
        },
        {
          value: "管家开门",
          label: "管家开门"
        }
      ],//看房方式
      plotSort:[
        {
          value: "1",
          label: "均价从低到高",
        },
        {
          value: "2",
          label: "均价从高到低",
        }
      ],//小区排序
      buildingList:[
        {
          value: "",
          label: "不限",
        },
        {
          value: "平层",
          label: "平层"
        },
        {
          value: "复式",
          label: "复式"
        },
        {
          value: "一楼带院",
          label: "一楼带院"
        },
        {
          value: "洋房",
          label: "洋房"
        },
        {
          value: "带露台",
          label: "带露台"
        },
        {
          value: "上叠",
          label: "上叠"
        },
        {
          value: "下叠",
          label: "下叠"
        }
      ],//房屋性质平层、复式、一楼带院、洋房、带露台、上叠、下叠
      plotPriceList:[
        {
          value: "0,500000",
          label: "50万以下"
        },
        {
          value: "500000,1000000",
          label: "50-100万"
        },
        {
          value: "1000000,1500000",
          label: "100-150万"
        },
        {
          value: "1500000,2000000",
          label: "150-200万"
        },
        {
          value: "2000000,3000000",
          label: "200-300万"
        },
        {
          value: "3000000,5000000",
          label: "300-500万"
        },
        {
          value: "5000000,8000000",
          label: "500-800万"
        },
        {
          value: "8000000,12000000",
          label: "800-1200万"
        },
        {
          value: "12000000,10000000000",
          label: "1200万以上"
        }
      ],//小区价格区间
      juliList:[
        {
          value: 1,
          label: "1公里"
        },
        {
          value: 2,
          label: "2公里"
        },
        {
          value: 3,
          label: "3公里"
        },
        {
          value: 4,
          label: "4公里"
        }
      ],
      conditionList:[
        {
          value: '全部',
          label: "全部"
        },
        {
          value: '已开盘',
          label: "已开盘"
        },
        {
          value: '售磬',
          label: "售磬"
        },
        {
          value: '未开盘',
          label: "未开盘"
        }
      ],//状态列表
      consolidationList:[
        {
          value: '',
          label: "不限"
        },
        {
          value: 2,
          label: "我的实勘"
        },
        {
          value: 1,
          label: "他人实勘"
        }
      ],//实勘列表
      houseAgeList:[
        {
          value: "",
          label: "不限",
        },
        {
          value: 1,
          label: "1年"
        },
        {
          value: 2,
          label: "2年"
        },
        {
          value: 3,
          label: "3年"
        },
        {
          value: 5,
          label: "5年"
        },
        {
          value: 7,
          label: "7年"
        },
        {
          value: 10,
          label: "10年"
        },
        {
          value: 15,
          label: "15年"
        },
        {
          value: 20,
          label: "20年"
        },
        {
          value: 30,
          label: "30年"
        },
        {
          value: 40,
          label: "40年"
        },
        {
          value: 50,
          label: "50年"
        },
        {
          value: 60,
          label: "60年"
        },
        {
          value: 70,
          label: "70年"
        },
      ],//房龄
      checkTimeList:[
        {
          value: '',
          label: "不限"
        },
        {
          value: '1天',
          label: "1天"
        },
        {
          value: '2天',
          label: "2天"
        },
        {
          value: '3天',
          label: "3天"
        },
        {
          value: '4天',
          label: "4天"
        },
        {
          value: '5天',
          label: "5天"
        },
        {
          value: '6天',
          label: "6天"
        },
        {
          value: '7天',
          label: "7天"
        },
        {
          value: '8天',
          label: "8天"
        },
        {
          value: '9天',
          label: "9天"
        },
        {
          value: '10天',
          label: "10天"
        },
      ],//最早入住时间
      zongPriceList:[
        {
          value: '',
          label: "不限"
        },
        {
          value: "0-500000",
          label: "50万以下"
        },
        {
          value: "500000-1000000",
          label: "50-100万"
        },
        {
          value: "1000000-1500000",
          label: "100-150万"
        },
        {
          value: "1500000-2000000",
          label: "150-200万"
        },
        {
          value: "2000000-3000000",
          label: "200-300万"
        },
        {
          value: "3000000-5000000",
          label: "300-500万"
        },
        {
          value: "5000000-8000000",
          label: "500-800万"
        },
        {
          value: "8000000-12000000",
          label: "800-1200万"
        },
        {
          value: "12000000-10000000000",
          label: "1200万以上"
        }
      ],
      zuPriceList:[
        {
          value: "0-2000",
          label: "2000以下"
        },
        {
          value: "2000-3000",
          label: "2000-3000元"
        },
        {
          value: "3000-4000",
          label: "3000-4000元"
        },
        {
          value: "4000-5000",
          label: "4000-5000元"
        },
        {
          value: "5000-6000",
          label: "5000-6000元"
        },
        {
          value: "6000-7000",
          label: "6000-7000元"
        },
        {
          value: "7000-8000",
          label: "7000-8000元"
        },
        {
          value: "8000-1000000",
          label: "8000以上"
        }
      ],//
      mianjiList:[
        {
          value: "0-50",
          label: "50m²以下"
        },
        {
          value: "51-75",
          label: "51-75/m²"
        },
        {
          value: "76-100",
          label: "76-100/m²"
        },
        {
          value: "101-160",
          label: "101-160/m²"
        },
        {
          value: "161-180",
          label: "161-180/m²"
        },
        {
          value: "181-100000",
          label: "181m²以上"
        }
      ],//面积区间
      floorList:[
        {
          value: 10000,
          label: "标间"
        },
        {
          value: 1,
          label: "1居"
        },
        {
          value: 2,
          label: "2居"
        },
        {
          value: 3,
          label: "3居"
        },
        {
          value: 4,
          label: "4居"
        },
        {
          value: 5,
          label: "5居"
        }
      ],
      levelList:[
        {
          value: "地下室",
          label: "地下室",
        },
        {
          value: "顶",
          label: "顶层",
        }
      ],//楼层
      openingTimeList:[
        {
          value: "近期开盘",
          label: "近期开盘",
        },
        {
          value: "1个月",
          label: "1个月",
        },
        {
          value: "2个月",
          label: "2个月",
        },
        {
          value: "3个月",
          label: "3个月",
        },
        {
          value: "4个月",
          label: "4个月",
        },
        {
          value: "6个月",
          label: "6个月",
        }
      ],//开盘时间
      registeredList:[
        {
          value: 1,
          label: "1人",
        },
        {
          value: 2,
          label: "2人",
        },
        {
          value: 3,
          label: "3人",
        },
        {
          value: 4,
          label: "4人",
        },
        {
          value: 5,
          label: "5人",
        },
        {
          value: 6,
          label: "6人",
        },
        {
          value: 7,
          label: "7人",
        }
      ],//户口情况
      paymentList:[
        {
          value: 1,
          label: "全款",
        },
        {
          value: 2,
          label: "按揭付款",
        }
      ],//付款方式
      zuiCheckTimeList:[
        {
          value: '3-5',
          label: "3-5天"
        },
        {
          value: '7-10',
          label: "7-10天"
        },
        {
          value: '10-15',
          label: "10-15天"
        },
        {
          value: '2021.3.26',
          label: "2021.3.26"
        }
      ],//求租最早入住时间，，，，，
      imageVideoList:[
        {
          value: '',
          label: '不限'
        },
        {
          value: 1,
          label: '有图'
        },
        {
          value: 2,
          label: '无图'
        },
        {
          value: 3,
          label: '有视频'
        },
        {
          value: 4,
          label: '无视频'
        },
        {
          value: 5,
          label: '有图有视频'
        },
        {
          value: 6,
          label: '无图无视频'
        }
      ],
      rentList:[
        {
          value: '',
          label: '不限'
        },
        {
          value: "整租",
          label: "整租",
        },
        {
          value: "合租",
          label: "合租",
        }
        
      ],//租赁方式
      rentStateList:[
        {
          value: '',
          label: '不限'
        },
        {
          value: "长租",
          label: "长租",
        },
        {
          value: "短租",
          label: "短租",
        }
      ],//租赁状态
      sourceList:[
        {
          value: "网络客",
          label: "网络客"
        },
        {
          value: "公客",
          label: "公客"
        },
        {
          value: "转介绍",
          label: "转介绍"
        },
        {
          value: "上门客",
          label: "上门客"
        }
      ],
      bambooList:[
        {
          value: '',
          label: '不限'
        },
        {
          value: 1,
          label: "1天"
        },
        {
          value: 2,
          label: "2天"
        },
        {
          value: 3,
          label: "3天"
        },
        {
          value: 4,
          label: "4天"
        },
        {
          value: 5,
          label: "5天"
        },
        {
          value: 6,
          label: "6天"
        },
        {
          value: 7,
          label: "7天"
        }
      ],//近七天
      fitmentTimeList:[
        {
          value: '',
          label: "不限"
        },
        {
          value: '半年内',
          label: '半年内'
        },
        {
          value: '1年',
          label: '1年'
        },
        {
          value: '2年',
          label: '2年'
        },
        {
          value: '3年',
          label: '3年'
        },
        {
          value: '4年',
          label: '4年'
        },
        {
          value: '5年',
          label: '5年'
        },
        {
          value: '6年',
          label: '6年'
        },
        {
          value: '7年',
          label: '7年'
        },
        {
          value: '8年',
          label: '8年'
        },
        {
          value: '9年',
          label: '9年'
        },
        {
          value: '10年',
          label: '10年'
        },
        {
          value: '10年以上',
          label: '10年以上'
        }
      ],//装修时间
      imageLayout:[
        {
          value: '',
          label: '不限'
        },
        {
          value: '已布置',
          label: '已布置'
        },
        {
          value: '未布置',
          label: '未布置'
        },
        {
          value: '已核验15天内',
          label: '已核验15天内'
        },
        {
          value: '已核验30天内',
          label: '已核验30天内'
        },
        {
          value: '已核验60天内',
          label: '已核验60天内'
        },
        {
          value: '未核验',
          label: '未核验'
        }
      ],//核验布置
      ownerPrice:[
        {
          value: '',
          label: '不限'
        },
        {
          value: '出',
          label: '出'
        },
        {
          value: '不出',
          label: '不出'
        },
        {
          value: '待沟通',
          label: '待沟通'
        }
      ],//业主费用
      keyCondition:[
        {
          value: '钥匙',
          label: '钥匙'
        },
        {
          value: '密码',
          label: '密码'
        },
        {
          value: '无',
          label: '无'
        }
      ],//钥匙情况
      electricityList:[
        {
          value: '民用',
          label: '民用'
        },
        {
          value: '商用',
          label: '商用'
        }
      ],//电费类型
      ownershipTypeList:[
        {
          value: '商品房',
          label: '商品房'
        },
        {
          value: '安置房',
          label: '安置房'
        },
        {
          value: '经济适用房',
          label: '经济适用房'
        }
      ],//权属类别
    }
  },
  methods: {
    getPosition(type) {
      let txt = "";
      switch (type) {
        case 1:
          txt = "区域总监";
          break;
        case 2:
          txt = "店长";
          break;
        case 3:
          txt = "经理";
          break;
        case 4:
          txt = "经纪人";
          break;
        case 5:
          txt = "人事";
          break;
        default:
          break;
      }
      return txt;
    },
    getHouseType(type) {
      let txt = ''
      switch (Number(type)) {
        case 1:
          txt = '新房'
          break;
        case 2:
          txt = '住宅'
          break;
        case 3:
          txt = '公寓'
          break;
        case 4:
          txt = '写字楼'
          break;
        case 5:
          txt = '商铺'
          break;
        case 6:
          txt = '别墅'
          break;
        default:
          break;
      }
      return txt
    },
    getClass(type) {
      let txt = ''
      switch (type) {
        case 1:
          txt = '展位'
          break;
        case 2:
          txt = 'A'
          break;
        case 3:
          txt = 'B'
          break;
        case 4:
          txt = 'C'
          break;
        case 5:
          txt = 'D'
          break;
        default:
          break;
      }
      return txt
    },
    getStatus (type) {
      let txt = ''
      switch (type) {
        case 1:
          txt = '未交易'
          break;
        case 3:
          txt = '有效暂缓'
          break;
        case 5:
          txt = '无效暂缓'
          break;
        case 7:
          txt = '我司成交'
          break;
        case 9:
          txt = '他司成交'
          break;
        default:
          break;
      }
      return txt
    },
    getUserInfo() {
      this.$axios.userInfo().then(res => {
        this.$store.commit('SET_USERINFO', res.data)
      })
    },
    getZuHousingTypeList() {
      if(this.userInfo.position === 4 || this.userInfo.position === 3) {
        return this.userInfo.fang_type_privilege === 1 ?
            this.zuHousingTypeList.filter(e => e.value === 4) :
            this.zuHousingTypeList.filter(e => e.value !== 4)
      } else {
        return this.zuHousingTypeList;
      }
    },
    getFTPrivilegeXiaoQuList(data) {
      if(this.userInfo.position === 4 || this.userInfo.position === 3) {
        return this.userInfo.fang_type_privilege === 1 ? data.filter(e => e.fang_type === 4) : data.filter(e => e.fang_type !== 4)
      } else {
        return data;
      }
    }
  },
  created() {

  },
}