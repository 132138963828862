import http from './index'
export default {
  // 设置 S
  aboutUs: (data) => http.post('/api/Settings/aboutUs', data),//关于我们
  houseLockLog: (data) => http.post('/api/Settings/houseLockLog', data),//长期锁定
  cancelLog: (data) => http.post('/api/Settings/cancel', data),//取消锁定
  houseChangeStatusList: (data) => http.post('/api/Settings/houseChangeStatusList', data),//房源变更状态列表
  staffDesc: (data) => http.post('/api/Settings/staffDesc', data),//员工法则
  // 设置 E
  //  团队 S
  teamList: (data) => http.post('/api/Teammember/teamList', data),//列表
  nextDianList: (data) => http.post('/api/Teammember/nextDianList', data),//区域总监查看下级-区域所有店长
  nextManagerList: (data) => http.post('/api/Teammember/nextManagerList', data),//店长查看下级-对应店铺所有经理
  nextBrokerList: (data) => http.post('/api/Teammember/nextBrokerList', data),//经理查看下级 - 对应所有经纪人
  changeBrokerFangTypePrivilege: (data) => http.post('/api/Teammember/changeBrokerFangTypePrivilege', data),//修改经纪人为写字楼经纪人

  //  团队 E
  //个人中心 S
  BangLookFangRefresh: (data) => http.post('/api/Indexx/BangLookFangRefresh', data),//个人中心-经纪人刷新

  subAskLeave: (data) => http.post('/api/Auditclients/subAskLeave', data),//个人中心-考勤-请假申请

  subBaoXiao: (data) => http.post('/api/Auditclients/subBaoXiao', data),//个人中心-考勤-报销申请

  askLeaveDetail: (data) => http.post('/api/Auditclients/askLeaveDetail', data),//个人中心-考勤-请假详情

  agreeAskLeave: (data) => http.post('/api/Auditclients/agreeAskLeave', data),//个人中心-考勤-请假-同意

  rejectAskLeave: (data) => http.post('/api/Auditclients/rejectAskLeave', data),//个人中心-考勤-请假-拒绝

  // 房源 S
  houseYueLookLog: (data) => http.post('/api/Looks/houseYueLookLog', data),//个人中心-房源-约看记录

  clientsGenLookLog: (data) => http.post('/api/Looks/clientsGenLookLog', data),//个人中心-跟进记录-客源

  genJinLog: (data) => http.post('/api/Looks/genJinLog', data),//个人中心-跟进记录-房源

  weiGuiLogList: (data) => http.post('/api/Honors/weiGuiLogList', data),//个人荣誉/违规-违规记录/晋升记录/获奖记录

  JinShengRule: (data) => http.post('/api/Auditpromotion/JinShengRule', data),//晋升规则

  addPromotion: (data) => http.post('/api/Honors/addPromotion', data),//个人中心-个人荣誉-晋升申请

  addPromotion2: (data) => http.post('/api/Honors/addPromotion2', data),//获奖申请

  addWeiGuiLog: (data) => http.post('/api/Honors/addWeiGuiLog', data),//个人中心-个人荣誉/违规-违规|获奖申请
  // 房源 E

  // 我的业绩 S
  commissionRecordList: (data) => http.post('/api/Commission/commissionRecordList', data),//我的业绩/他人业绩
  // 我的业绩 E
  // 黑名单 S
  blackList: (data) => http.post('/api/Blacklist/blackList', data),//黑名单列表-客源
  houseBlackList: (data) => http.post('/api/Blacklist/houseBlackList', data),//黑名单列表-房源
  removeBlack: (data) => http.post('/api/Blacklist/remove', data),//移除黑名单
  deleteBlack: (data) => http.post('/api/blacklist/delC', data),//彻底删除
  collectList: (data) => http.post('/api/Blacklist/collectList', data),//关注列表
  collectRemove: (data) => http.post('/api/Blacklist/collectRemove', data),//取消关注
  // 黑名单 E
  // 消息列表 S
  messageList: (data) => http.post('/api/Message/messageList', data),//消息列表
  // 消息列表 E

  // 审核状态 S
  deleteAudit: (data) => http.post('/api/Auditclients/deleteList', data),//删除审核-客源

  houseDelList: (data) => http.post('/api/Audithouse/houseDelList', data),//1审核删除-房源-列表

  cancelHousing: (data) => http.post('/api/Audithouse/cancelDel', data),//1审核删除-房源-取消

  rejectHouseDel: (data) => http.post('/api/Audithouse/rejectHouseDel', data),//1审核删除-房源-拒绝

  cancelPassenger: (data) => http.post('/api/Auditclients/cancel2', data),//2删除审核-客源-取消


  anewSubmit: (data) => http.post('/api/Audithouse/anewSub', data),//删除审核-客源-重新提交

  agreeHouseDel: (data) => http.post('/api/Audithouse/agreeHouseDel', data),//1审核删除-房源-同意

  // rejectHouseDel: (data) => http.post('/api/Auditclients/rejectHouseDel',data),//1审核删除-房源-拒绝

  agreePassenger: (data) => http.post('/api/Auditclients/agree2', data),//2删除审核-客源-同意删除【区域总监有权限删除】

  rejectPassenger: (data) => http.post('/api/Auditclients/reject2', data),//2删除审核-客源-拒绝删除【区域总监有权限删除】

  anewPassenger: (data) => http.post('/api/Auditclients/anewSub2', data),//2删除审核-客源-重新提交



  askLeaveList: (data) => http.post('/api/Auditclients/askLeaveList', data),//请假列表

  checkInLog: (data) => http.post('/api/Checkingin/checkInLog', data),//考勤记录

  auditClientsList: (data) => http.post('/api/Auditclients/auditClientsList', data),//信息变更-客源列表

  houseChangeList: (data) => http.post('/api/Audithouse/houseChangeList', data),//信息变更-房源-列表

  houseChangeDetail: (data) => http.post('/api/Audithouse/houseChangeDetail', data),//1信息变更-房源-详情


  agree1: (data) => http.post('/api/Audithouse/agree1', data),//1信息变更-房源-同意


  reject1: (data) => http.post('/api/Audithouse/reject1', data),//1信息变更-房源-拒绝

  auditClientsDetail: (data) => http.post('/api/Auditclients/auditClientsDetail', data),//信息变更-客源详情

  agreeClients: (data) => http.post('/api/Auditclients/agreeClients', data),//信息变更-客源-同意

  jvjClients: (data) => http.post('/api/Auditclients/jvjClients', data),//信息变更-客源-拒绝

  baoXList: (data) => http.post('/api/Auditclients/baoXList', data),//报销列表

  baoXDetail: (data) => http.post('/api/Auditclients/baoXDetail', data),//报销审核-详情

  agreeBaoXiao: (data) => http.post('/api/Auditclients/agreeBaoXiao', data),//报销审核-同意

  rejectBaoXiao: (data) => http.post('/api/Auditclients/rejectBaoXiao', data),//报销审核-拒绝

  cancelBaoXiao: (data) => http.post('/api/Auditclients/cancelBaoXiao', data),//报销审核-取消

  xQuList: (data) => http.post('/api/Auditxiaoqu/xQuList', data),//审核小区-列表

  rejectPlot: (data) => http.post('/api/Auditxiaoqu/reject', data),//审核小区-拒绝

  agreePlot: (data) => http.post('/api/Auditxiaoqu/agree', data),//审核小区-同意

  cancelPlot: (data) => http.post('/api/Auditxiaoqu/cancel', data),//审核小区-取消

  promotionList: (data) => http.post('/api/Auditpromotion/promotionList', data),//审核状态-晋升列表

  promotionDetail: (data) => http.post('/api/Auditpromotion/promotionDetail', data),//审核状态-晋升详情

  agreePromote: (data) => http.post('/api/Auditpromotion/agree3', data),//3审核状态-晋升同意

  rejectPromote: (data) => http.post('/api/Auditpromotion/reject3', data),//3审核状态-晋升拒绝


  shenHeContract: (data) => http.post('/api/Contract/shenHeContract', data),//合同审核

  baoGaoDeal: (data) => http.post('/api/Contract/baoGaoDeal', data),//成交报告

  baoGaoDealDetail: (data) => http.post('/api/Contract/baoGaoDealDetail', data),//成交报告详情

  baoGaoDealQ: (data) => http.post('/api/Contract/baoGaoDealQ', data),//成交报告审核取消

  baoGaoDealJ: (data) => http.post('/api/Contract/baoGaoDealJ', data),//合同审核拒绝

  baoGaoDealT: (data) => http.post('/api/Contract/baoGaoDealT', data),//成交报告审核通过

  // 审核状态 E

  // 合同管理 S
  contractReceive: (data) => http.post('/api/Contract/contractReceive', data),//合同领取列表

  contractReceiveDetail: (data) => http.post('/api/Contract/contractReceiveDetail', data),//合同领取详情

  shenHeContractDetail: (data) => http.post('/api/Contract/shenHeContractDetail', data),//合合同审核详情

  shenHeContractJ: (data) => http.post('/api/Contract/shenHeContractJ', data),//合同审核拒绝

  shenHeContractT: (data) => http.post('/api/Contract/shenHeContractT', data),//合同审核通过

  contractUpload: (data) => http.post('/api/Contract/contractUpload', data),//上传合同列表

  contractDetails: (data) => http.post('/api/Contract/contractDetails', data),//上传合同详情
  // 合同管理 E
  // 交易记录 S
  SignAContract: (data) => http.post('/api/Contract/SignAContract', data),//线上签约列表

  SignAContractDetail: (data) => http.post('/api/Contract/SignAContractDetail', data),//线上签约详情

  termination: (data) => http.post('/api/Contract/termination', data),//终止合作

  baoGao: (data) => http.post('/api/Contract/baoGao', data),//成交报告

  fileLoks: (data) => http.post('/api/usershouse/fileLoks', data),//查看附件

  offlineContract: (data) => http.post('/api/Contract/offlineContract', data),//线下签约列表
  // 交易记录 E

  // 钥匙管理 S
  keyList: (data) => http.post('/api/Keylist/keyList', data),//完善率列表（接口分页请使用data/data下面的数据判断分页）

  keyJieList: (data) => http.post('/api/Keylist/keyJieList', data),//借还记录

  keyAdd: (data) => http.post('/api/Keylist/keyAdd', data),//借钥匙

  keyUpdate: (data) => http.post('/api/Keylist/keyUpdate', data),//还钥匙

  keyJieLists: (data) => http.post('/api/Keylist/keyJieLists', data),//借还记录列表
  // 钥匙管理 E
  //业绩目标 S

  getTargetInfo: (data) => http.post('/api/Target/getTargetInfo', data),//今日目标

  getTargetMonthInfo: (data) => http.post('/api/Target/getTargetMonthInfo', data),//本月目标

  areaList: (data) => http.post('/api/Target/areaList', data),//区域总监列表-根据当前身份显示数据

  setPower: (data) => http.post('/api/Target/setPower', data),//权限设置

  addAim: (data) => http.post('/api/Target/addAim', data),//制定目标

  checkYeJi: (data) => http.post('/api/Target/checkYeJi', data),//查看业绩

  nextLevelDianList: (data) => http.post('/api/Target/nextLevelDianList', data),//区域总监查看下级

  nextManagersList: (data) => http.post('/api/Target/nextManagersList', data),//店长查看下级

  TargetClientList: (data) => http.post('/api/Target/clientList', data),//业绩目标--新增客户列表

  //业绩目标 E

}