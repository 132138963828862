import http from './index'
import personage from './personage'
export default {
  ...personage,
  // 公共 S
  captcha: (data) => http.post('/api/login/captcha', data),//验证码

  userList: (data) => http.post('/api/common/userList', data),//用户列表

  map1: (data) => http.post('/api/Common/map1', data),//周边推荐

  map2: (data) => http.post('/api/Common/map2', data),//城市列表

  address: (data) => http.post('/api/Common/address', data),//获取经纬度

  location: (data) => http.post('/api/common/location', data),//经纬度转换地区

  xius: (data) => http.post('/api/common/xius', data),//信息
  // 公共 E
  // 登录 S
  loginPc: (data) => http.post('/api/login/loginPc', data),//PC登录
  passwordForget: (data) => http.post('/api/login/passwordForget', data),//PC忘记密码
  userInfo: (data) => http.post('/api/login/userInfo', data),//获取用户信息

  // 登录 S
  // 首页 S
  messageList: (data) => http.post('/api/Message/messageList', data),//消息列表

  messageDetail: (data) => http.post('/api/Message/messageDetail', data),//消息详情

  bottomInfo: (data) => http.post('/api/Article/bottomInfo', data),//底部信息

  shangQuan: (data) => http.post('/api/common/shangQuan', data),//商圈

  xiaoQuHuXing: (data) => http.post('/api/common/xiaoQuHuXing', data),//小区户型

  xiaoQuBiaoQian: (data) => http.post('/api/index/xiaoQuBiaoQian', data),//小区标签

  xiaoQuHuAdd: (data) => http.post('/api/index/xiaoQuHuAdd', data),//小区添加户型

  xiaoQuList: (data) => http.post('/api/index/xiaoQuList', data),//小区列表

  xiaoQuDetail: (data) => http.post('/api/index/xiaoQuDetail', data),//小区详情

  xiaoQuTi: (data) => http.post('/api/common/xiaoQuTi', data),//小区梯户

  xiaoQuTongJi: (data) => http.post('/api/index/xiaoQuTongJi', data),//小区统计

  louList: (data) => http.post('/api/Index/louList', data),//小区统计-楼栋号列表

  unitsList: (data) => http.post('/api/index/unitsList', data),//小区统计-单元列表

  hotHouse: (data) => http.post('/api/index/hotHouse', data),//热卖房源-热租房源

  xiaoQuEdit: (data) => http.post('/api/index/xiaoQuEdit', data),//小区编辑

  xiaoQuHuDel: (data) => http.post('/api/index/xiaoQuHuDel', data),//小区编辑 小区删除户型

  xiaoQuHuEdit: (data) => http.post('/api/index/xiaoQuHuEdit', data),//小区编辑户型

  xiaoQuImages: (data) => http.post('/api/index/xiaoQuImages', data),//小区编辑图片信息

  indexFang: (data) => http.post('/api/index/indexFang', data),//首页展位房源

  indexTongJi: (data) => http.post('/api/index/indexTongJi', data),//首页每日统计

  indexClienfList: (data) => http.post('/api/index/indexClienfList', data),//首页每日统计--新增客户列表


  indexFangList: (data) => http.post('/api/index/indexFangList', data),//首页每日统计--新增房源列表

  indexDaiKan: (data) => http.post('/api/index/indexDaiKan', data),//首页每日统计--新增带看

  indexLook: (data) => http.post('/api/index/indexLook', data),//首页每月统计--新增越看

  indexGenJin: (data) => http.post('/api/index/indexGenJin', data),//首页每日统计--新增跟进

  indexXinXi: (data) => http.post('/api/index/indexXinXi', data),//首页每日统计--首页每日统计--新增信息方等















  articleList: (data) => http.post('/api/Article/articleList', data),//大事件
  articleDetail: (data) => http.post('/api/Article/articleDetail', data),//大事件详情
  buyFlow: (data) => http.post('/api/Article/buyFlow', data),//首页-买卖流程 - 租赁流程
  xiaoQuApply: (data) => http.post('/api/index/xiaoQuApply', data),//申请小区

  clientLookLog: (data) => http.post('/api/Looks/clientLookLog', data),//带看管理-客源列表【业绩统计里的客源也可用】

  // 首页 E
  // 微课堂 S
  classroomBanner: (data) => http.post('/api/classroom/banner', data),//PC微课堂 轮播图
  classroom: (data) => http.post('/api/classroom/classroom', data),//PC微课堂
  classroomDetail: (data) => http.post('/api/classroom/classroomDetail', data),//PC微课堂课程详情
  classroomCatalogue: (data) => http.post('/api/classroom/classroomCatalogue', data),//PC微课堂课程目录
  classroomCatalogueDetail: (data) => http.post('/api/classroom/classroomCatalogueDetail', data),//PC微课堂课程视频
  kaoShi: (data) => http.post('/api/classroom/kaoShi', data),//PC微课堂考试
  kaoShiDetail: (data) => http.post('/api/classroom/kaoShiDetail', data),//PC微课堂试题
  kaoShiOrder: (data) => http.post('/api/classroom/kaoShiOrder', data),//PC微课堂考试提交
  kaoShiAnalysis: (data) => http.post('/api/classroom/kaoShiAnalysis', data),//PC微课堂试题解析
  kaoShiList: (data) => http.post('/api/classroom/kaoShiList', data),//PC微课堂往期考试记录
  kaoShiLists: (data) => http.post('/api/classroom/kaoShiLists', data),//PC微课堂下级考试记录
  // 微课堂 E

  // 公共接口 S
  xiaoQu: (data) => http.post('/api/common/xiaoQu', data),//小区信息
  diTie: (data) => http.post('/api/common/diTie', data),//地铁线路
  diTieZhan: (data) => http.post('/api/common/diTieZhan', data),//地铁线路站点
  fangYuan: (data) => http.post('/api/common/fangYuan', data),//房源信息来源
  menDian: (data) => http.post('/api/common/menDian', data),//门店列表
  menDianC: (data) => http.post('/api/common/menDianC', data),//门店列表（根据区域
  upload: (data) => http.post('/api/common/upload', data),//上传文件
  fangSpans: (data) => http.post('/api/common/fangSpans', data),//房源标签
  huXing: (data) => http.post('/api/common/huXing', data),//户型
  xinZi: (data) => http.post('/api/common/xinZi', data),//薪资模式
  renShi: (data) => http.post('/api/common/renShi', data),//人事信息
  quDao: (data) => http.post('/api/common/quDao', data),//面试人员来源
  // xiaoQuList: (data) => http.post('/api/index/xiaoQuList',data),//小区列表
  // 公共接口 E

  // 房源接口 S
  houseShouAdd: (data) => http.post('/api/house/houseShouAdd', data),//添加房源（出售）

  houseShouEdit: (data) => http.post('/api/house/houseShouEdit', data),//编辑房源（出售）

  houseZuAdd: (data) => http.post('/api/house/houseZuAdd', data),//添加房源（出租）

  houseZuEdit: (data) => http.post('/api/house/houseZuEdit', data),//编辑房源（出租）
  editZuHouse: (data) => http.post('/api/House/editZuHouse', data),//编辑房源（出租）

  houseZuAddImages: (data) => http.post('/api/house/houseZuAddImages', data),//添加房源图片（出租）
  fangCheckLog: (data) => http.post('/api/House/fangCheckLog', data),//房源列表-其他页面进房源列表时请求【清除查看记录接口】

  clientCheckLog: (data) => http.post('/api/clients/clientCheckLog', data),//房源列表-其他页面进房源列表时请求【清除查看记录接口】


  houseZuEditImages: (data) => http.post('/api/house/houseZuEditImages', data),//编辑房源图片（出租）

  houseShouAddImages: (data) => http.post('/api/house/houseShouAddImages', data),//添加房源图片（出售）

  houseShouEditImages: (data) => http.post('/api/house/houseShouEditImages', data),//编辑房源图片（出售

  houseDetail: (data) => http.post('/api/house/houseDetail', data),//房源详情

  houseZuDetail: (data) => http.post('/api/house/houseZuDetail', data),//房源出租详情

  fangHei: (data) => http.post('/api/house/fangHei', data),//房源加入黑名单/取消

  fangGuanZhu: (data) => http.post('/api/house/fangGuanZhu', data),//房源关注/取消

  fangLouLook: (data) => http.post('/api/house/fangLouLook', data),//房源详情查看楼栋号


  fangZuLouLook: (data) => http.post('/api/house/fangZuLouLook', data),//房源详情查看楼栋号（出租）

  downloadContract: (data) => http.post('/api/house/downloadContract', data),//下载合同

  zuDaiKans: (data) => http.post('/api/house/zuDaiKans', data),//下载合同


  fangList: (data) => http.post('/api/house/fangList', data),//房源列表

  fangStatusNum: (data) => http.post('/api/house/fangStatusNum', data),//房源类型数量

  fangTui: (data) => http.post('/api/house/fangTui', data),//房源列表（推）

  houseClient: (data) => http.post('/api/house/houseClient', data),//房源详情经纪人信息
  myDaiKanList: (data) => http.post('/api/Usershouse/myDaiKanList', data),//我的--房源详情带看反馈

  houseMobileLook: (data) => http.post('/api/house/houseMobileLook', data),//房源详情手机号跟进

  houseLouLook: (data) => http.post('/api/house/houseLouLook', data),//房源详情楼栋号跟进

  fangLouLookUpdate: (data) => http.post('/api/house/fangLouLookUpdate', data),//房源详情查看楼栋号记录跟进

  fangZuLouLookUpdate: (data) => http.post('/api/house/fangZuLouLookUpdate', data),//房源详情查看楼栋号记录跟进
  xinXiUpdate: (data) => http.post('/api/house/xinXiUpdate', data),//信息变更



  fangMobileLook: (data) => http.post('/api/house/fangMobileLook', data),//房源详情查看业主电话

  fangZuMobileLook: (data) => http.post('/api/house/fangZuMobileLook', data),//房源详情查看业主电话（出租）

  soundsLock: (data) => http.post('/api/house/soundsLock', data),//录音列表

  soundsTing: (data) => http.post('/api/house/soundsTing', data),//录音上传

  soundsNum: (data) => http.post('/api/house/soundsNum', data),//查看录音


  fangMobileLookUpdate: (data) => http.post('/api/house/fangMobileLookUpdate', data),//房源详情查看业主号码记录跟进

  fangZuMobileLookUpdate: (data) => http.post('/api/house/fangZuMobileLookUpdate', data),//房源详情查看业主号码记录跟进

  fangDel: (data) => http.post('/api/house/fangDel', data),//房源删除

  contract: (data) => http.post('/api/house/contract', data),//合同协议

  fangZhang: (data) => http.post('/api/common/fangZhang', data),//见证方信息

  houseContract: (data) => http.post('/api/house/houseContract', data),//提交合同信息

  fengXian: (data) => http.post('/api/Honors/fengXian', data),//房屋风险告知书提交

  jiaoJie: (data) => http.post('/api/Honors/jiaoJie', data),//房屋交接清单提交

  yongJinS: (data) => http.post('/api/Honors/yongJinS', data),//房屋佣金确认书提交

  lookS2: (data) => http.post('/api/Honors/lookS2', data),//详情查看合同附件信息

  houseLocking: (data) => http.post('/api/house/houseLocking', data),//房源锁定

  quS: (data) => http.post('/api/house/quS', data),//房源取消锁定

  fangLookGuiZe: (data) => http.post('/api/house/fangLookGuiZe', data),//信息方规则

  notTradingClientList: (data) => http.post('/api/Clients/notTradingClientList', data),//未交易客户列表




  // 房源接口 E
  // 淘宝池 S
  taoBaoPool: (data) => http.post('/api/Pool/taoBaoPool', data),//淘宝池列表
  poolDetail: (data) => http.post('/api/Pool/poolDetail', data),//淘宝池详情

  // addClient: (data) => http.post('/api/Clients/addClient', data),//淘宝池详情
  // 淘宝池 E

  // 业绩统计 S
  qiYeRanking: (data) => http.post('/api/Ranking/qiYeRanking', data),//企业前三名

  userRanking: (data) => http.post('/api/Ranking/userRanking', data),//个人业绩

  usersRanking: (data) => http.post('/api/Ranking/usersRanking', data),//团队业绩

  turnover: (data) => http.post('/api/Ranking/turnover', data),//成交量

  newCountList: (data) => http.post('/api/Ranking/newCountList', data),//新增量

  // 业绩统计 E


  // 人才库 S
  personnelList: (data) => http.post('/api/Personnel/personnelList', data),//人才库列表
  personnelAdd: (data) => http.post('/api/Personnel/personnelAdd', data),//人才库添加
  userRen: (data) => http.post('/api/Personnel/userRen', data),//人才库人才选择信息
  personnelEdit: (data) => http.post('/api/Personnel/personnelEdit', data),//人才库编辑
  personnelDel: (data) => http.post('/api/Personnel/personnelDel', data),//删除
  personnelDetail: (data) => http.post('/api/Personnel/personnelDetail', data),//人才库详情
  // 人才库 E

  // PC人事接口 S
  staffList: (data) => http.post('/api/pcuser/staffList', data),//PC人事--员工管理

  staffDetail: (data) => http.post('/api/pcuser/staffDetail', data),//PC人事--员工详情

  staffAdd: (data) => http.post('/api/pcuser/staffAdd', data),//PC人事--新增员工

  staffUpdate: (data) => http.post('/api/pcuser/staffUpdate', data),//PC人事--修改员工

  liZhi: (data) => http.post('/api/pcuser/liZhi', data),//PC人事--离职

  theShop: (data) => http.post('/api/pcuser/theShop', data),//PC人事--调店

  interviewList: (data) => http.post('/api/pcuser/interviewList', data),//PC人事--面试管理

  interviewDetail: (data) => http.post('/api/pcuser/interviewDetail', data),//PC人事--面试详情

  interviewStatus: (data) => http.post('/api/pcuser/interviewStatus', data),//PC人事--面试审核

  interviewAdd: (data) => http.post('/api/pcuser/interviewAdd', data),//PC人事--面试添加

  interviewEdit: (data) => http.post('/api/pcuser/interviewEdit', data),//PC人事--面试编辑

  // 客源 S
  addClient: (data) => http.post('/api/Clients/addClient', data),//添加客源

  editClient: (data) => http.post('/api/Clients/editClient', data),//编辑客源

  clientList: (data) => http.post('/api/Clients/clientList', data),//客源列表

  clientJ: (data) => http.post('/api/contract/clientJ', data),//客源列表

  clientsNums: (data) => http.post('/api/clients/clientsNums', data),//公客私客数量

  getClientInfo: (data) => http.post('/api/Clients/getClientInfo', data),//获取客源信息

  clientDetail: (data) => http.post('/api/Clients/clientDetail', data),//客源详情

  bianClinet: (data) => http.post('/api/Clients/bianClinet', data),//变更客源到私客

  joinBlack: (data) => http.post('/api/Clients/joinBlack', data),//加入黑名单

  lookLog: (data) => http.post('/api/Clients/lookLog', data),//客源详情-带看记录

  changeClient: (data) => http.post('/api/Clients/changeClient', data),//客源详情-变更客源

  checkTel: (data) => http.post('/api/Clients/checkTel', data),//客源详情-查看联系方式

  delClient: (data) => http.post('/api/Clients/delClient', data),//客源详情-删除客源-需要区域总监审核

  addGn: (data) => http.post('/api/Clients/addGn', data),//我要跟进

  addDk: (data) => http.post('/api/Clients/addDk', data),//提交-带看记录

  addYk: (data) => http.post('/api/Clients/addYk', data),//提交-约看记录

  contractUploadAdd: (data) => http.post('/api/Contract/contractUploadAdd', data),//上传合同

  newLouDong: (data) => http.post('/api/common/newLouDong', data),//上传合同
  

  


  // 客源 E

  // PC人事接口 E
  // 上传
  uploadFile: (params) => {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      let param = new FormData(); // 创建form对象
      param.append("file", params); // 通过append向form对象添加数据
      http.post('/api/common/upload', param).then(res => {
        if (res.code === 1) {
          resolve(res.data);
        }
      })

    });
  },
  fangImport: (params) => {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      let param = new FormData(); // 创建form对象
      param.append("file", params.file); // 通过append向form对象添加数据
      param.append("fang_type", params.type); // 通过append向form对象添加数据
      http.post('/api/house/fangImport', param).then(res => {
        if (res.code === 1) {
          resolve(res.data);
        }
      })

    });
  }
}