import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    city:'郑州市',//城市
    region:'河南省/郑州市/郑东新区' || localStorage.getItem("region"),//地区
    location:{
      lng:'113.611083',
      lat:'34.80152'
    },//经纬度
    token: '' || localStorage.getItem("token"),//J8OT3KO7
    userInfo: localStorage.getItem("userInfo") || {},//个人信息
  },
  mutations: {
    SET_USERINFO (state,data) {
      let new1 = data
      state.userInfo = {}
      state.userInfo = new1
      localStorage.setItem("userInfo",JSON.stringify(data))
    },
    //设置token
    SET_TOKEN (state,data) {
      state.token = data
      localStorage.setItem("token",data)
    },
    //删除token
    REMOVE_TOKEN (state) {
      state.token = ''
      state.userInfo = {}
      localStorage.removeItem('token')
      localStorage.clear()
    },
    //设置城市
    SET_CITY (state,data) {
      state.city = data.city
    },
    SET_REGION (state,data) {
      state.region = data
      localStorage.setItem("region",data)
    },
    //设置经纬度
    SET_LOCATION (state,data) {
      state.location = data
    }
  },
  getters: {
    userInfo (state) {
      return state.userInfo
    },
    token (state) {
      return state.token
    },
    city (state) {
      return state.city
    },
    region (state) {
      return state.region
    }
  },
  actions: {},
  modules: {},
});
