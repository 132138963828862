<template>
  <div class="footer m-t-40">
    <div class="footer_main w-1200 margin-auto flex">
      <div class="footer_con">
        <ul class="footer_ul flexs">
          <router-link custom v-slot="{ navigate }" :to="item.url" v-for="(item,index) in footerList" :key="index">
            <li class="" @click="navigate" @keypress.enter="navigate" role="link">{{ item.name }}</li>
          </router-link>
        </ul>
        <ul class="footer_con_list flexs m-t-40">
          <li>
            <div class="footer_con_list_img m-r-10">
              <img src="../assets/image/publice/phone.png" alt="">
            </div>
            <div class="footer_con_list_bot">
              <div class="">咨询电话：</div>
              <div class="">{{ message.phone }}</div>
            </div>
          </li>
          <li>
            <div class="footer_con_list_img m-r-10">
              <img src="../assets/image/publice/address.png" alt="">
            </div>
            <div class="footer_con_list_bot">
              <div class="">公司地址：</div>
              <div class="">{{ message.address }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="footer_main_code center flex-direction">
        <img :src="message.logo" alt="">
        <span>新有居小程序</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      footerList:[
        {
          name:'首页',
          url:'/'
        },
        {
          name:'淘宝池',
          url:'/taobao'
        },
        {
          name:'房源管理',
          url:'/housing?type=2'
        },
        {
          name:'客源管理',
          url:'/passenger?type=1'
        },
        {
          name:'微课堂',
          url:'/microlecture?type=2'
        },
        {
          name:'人才库',
          url:'/talents'
        },
        {
          name:'业绩统计',
          url:'/performance'
        },
        {
          name:'个人中心',
          url:'/personage/aboutSee'
        }
      ],
      message:{},//
    }
  },
  methods:{
    getbottomInfo () {
      this.$axios.bottomInfo().then(res=>{
        this.message = res.data
      })
    }
    
  },
  created () {
    this.getbottomInfo()
  }
}
</script>

<style lang="less" scoped>
.footer {
  
  background: #292D32;
  .footer_main {
    height: 180px;
  }
  .footer_main_code {
    img {
      width: 100px;
      height: 100px;
    }
    span {
      color: #FFFFFF;
      font-size: 14px;
      margin-top: 10px;
    }
  }
  .footer_ul {
    li {
      cursor: pointer;
      color: #FFFFFF;
      font-size: 18px;
      margin-right: 45px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.footer_con_list {
  li {
    display: flex;
    margin-right: 60px;
    align-items: center;
    .footer_con_list_img {
      width: 44px;
      height: 44px;
      
      img {
        border-radius: 50%;
        background: #79A5FF;
      }
    }
    .footer_con_list_bot {
      display: flex;
      height: 44px;
      flex-direction: column;
      justify-content: space-between;
      div {
        color: #FFFFFF;
        font-size: 14px;
      }
    }
  }
}
</style>