<template>
  <div class="navigation">
      <div class="navigation_top">
        <div class="navigation_top_main w-1200 margin-auto flex">
          <div class="navigation_city flexs" @click="isCity = !isCity">
            <img src="../assets/image/publice/city.png" alt="">
            <span>{{ city }}</span>
            <img src="../assets/image/publice/b_more.png" alt="">
          </div>
          <div class="navigation_login" v-if="token">
            <el-dropdown @command="quitLogin">
              <div class="navigation_login_head center">
                <img :src="userInfo.image" alt="">
                <img src="../assets/image/publice/bot.png" alt="">
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="index" v-for="(item,index) in 2" :key="index">{{ index == 0 ? '个人资料' : '退出' }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="orientation p-t-20 p-r-20 p-l-20" v-if="isCity">
            <div class="orientation_head">
              <div class="orientation_head_txt">热门城市</div>
              <ul class="orientation_head_list flexs">
                <li @click="selectCity(item)" v-for="(item,index) in hotList" :key="index">{{ item }}</li>
              </ul>
            </div>
            <div class="search flexs p-l-10 p-r-10 m-t-20 m-b-20">
              <img src="../assets/image/publice/search.png" alt="">
              <el-input @input="changeCity" v-model="citys" placeholder="搜索城市"></el-input>
            </div>
            <div class="city_head flexs">
              <div @click="cityIndex = index" class="city_head_li m-r-20" :class="{active:index == cityIndex}" v-for="(item,index) in cityList" :key="index">{{ item.name }}</div>
            </div>
            <div class="orientation_main">
              <ul class="orientation_main_list">
                <li v-for="(item,index) in cityList[cityIndex].array" :key="index">
                  <div class="english">{{ item.name }}</div>
                  <div class="orientation_main_city flex-wrap">
                    <div @click="selectCity(em)" class="orientation_main_city_li" v-for="(em,ind) in item.array" :key="ind">{{ em }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="navigation_head b-c-f">
        <div class="navigation_head_mian w-1200 margin-auto flex ">
          <div class="navigation_logo">
            <img src="../assets/image/login/logo1.png" alt="">
          </div>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            active-text-color="#3273F6"
            text-color="#666666"
            @select="handleSelect"
          >
           <el-menu-item index="0" :disabled="userInfo.position == 5">首页</el-menu-item>
           <el-menu-item index="1" :disabled="userInfo.position == 5">淘宝池</el-menu-item>
           <el-menu-item index="2" :disabled="userInfo.position == 5">房源管理</el-menu-item>
           <!-- <el-submenu index="2" :disabled="userInfo.position == 5">
              <template slot="title">房源管理</template>
              <el-menu-item index="2-1">出售</el-menu-item>
              <el-menu-item index="2-2">出租</el-menu-item>
            </el-submenu> -->
            <el-menu-item index="3" :disabled="userInfo.position == 5">客源管理</el-menu-item>
            <!-- <el-submenu index="3" :disabled="userInfo.position == 5">
              <template slot="title">客源管理</template>
              <el-menu-item index="3-1">求租</el-menu-item>
              <el-menu-item index="3-2">求购</el-menu-item>
            </el-submenu> -->
            <el-menu-item index="4" :disabled="userInfo.position == 5">微课堂</el-menu-item>
            <!-- <el-submenu index="4" :disabled="userInfo.position == 5">
              <template slot="title">微课堂</template>
              <el-menu-item index="4-1">买卖培训</el-menu-item>
              <el-menu-item index="4-2">租赁培训</el-menu-item>
            </el-submenu> -->
            <el-menu-item index="5" :disabled="userInfo.position == 5">人才库</el-menu-item>
            <el-menu-item index="6" :disabled="userInfo.position == 5">业绩统计</el-menu-item>
            <el-menu-item index="7">个人中心</el-menu-item>
            <!-- <template v-for="(item,index) in navList">
              <el-submenu :index="item.index" v-if="item.children" :key="index">
                <template slot="title">{{ item.name }}</template>
                <el-menu-item :index="em.index" v-for="(em,ind) in item.children" :key="ind">{{ em.name }}</el-menu-item>
              </el-submenu>
              <el-menu-item :index="item.index" :key="index" v-else>{{ item.name }}</el-menu-item>
            </template> -->
          </el-menu>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../store/index'
import { regionData, CodeToText } from "element-china-area-data";
export default {
   computed: {
  // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters([
      'userInfo',
      'token',
      'city'
    ])
  },
data() {
    return {
      regionList: regionData,
      CodeToText: CodeToText,
      urlShow: true,
      areaList:[],
      activeIndex:'0',
      isCity:false,
      navList:[
        {
          name:'首页',
          url:'/',
          index:'0'
        },
        {
          name:'淘宝池',
          url:'/taobao',
          index:'1'
        },
        {
          name:'房源管理',
          url:'/housing?type=2',
          index:'2',
          // children:[
          //   {
          //     name:'出售',
          //     url:'/housing?type=1',
          //     index:'2-1'
          //   },
          //   {
          //     name:'出租',
          //     url:'/housing?type=2',
          //     index:'2-2'
          //   }
          // ]
        },
        {
          name:'客源管理',
          url:'/passenger?type=1',
          index:'3',
          // children:[
          //   {
          //     name:'求租',
          //     url:'/passenger?type=1',
          //     index:'3-1'
          //   },
          //   {
          //     name:'求购',
          //     url:'/passenger?type=2',
          //     index:'3-2'
          //   }
          // ]
        },
        {
          name:'微课堂',
          url:'/microlecture?type=2',
          index:'4',
          // children:[
          //   {
          //     name:'买卖培训',
          //     url:'/microlecture?type=1',
          //     index:'4-1'
          //   },
          //   {
          //     name:'租赁培训',
          //     url:'/microlecture?type=2',
          //     index:'4-2'
          //   }
          // ]
        },
        {
          name:'人才库',
          url:'/talents',
          index:'5'
        },
        {
          name:'业绩统计',
          url:'/performance',
          index:'6'
        },
        {
          name:'个人中心',
          url:'/personage/aboutSee',
          index:'7'
        }
      ],
      citys:'',
      hotList:['北京','南京','上海','杭州','苏州','天津','深圳','成都'],
      cityIndex:0,//
      cityList:[
        {
          name:'ABCDE',
          array:[
            {
              name:'A',
              array:['安庆','安阳']
            },
             {
              name:'B',
              array:['北京','蚌埠','宝鸡','滨州']
            },
             {
              name:'C',
              array:['长春','长沙','成都','重庆','常州','常德','常熟','郴州','滁州','长治']
            },
             {
              name:'D',
              array:['大同','德州','东营']
            },
             {
              name:'E',
              array:['鄂州']
            }
          ]
        },
         {
          name:'FGHJ',
          array:[
            {
              name:'F',
              array:['福州','阜阳']
            },
            {
              name:'G',
              array:['广州','贵阳','赣州']
            },
            {
              name:'H',
              array:['哈尔滨','杭州','合肥','海口','衡阳','淮安','湖州','汉中','菏泽','怀化','淮北','淮南','黄冈','黄石']
            },
            {
              name:'J',
              array:['济南','嘉兴','金华','荆州','济宁','九江','吉安','焦作','晋中','荆门']
            }
          ]
        },
         {
          name:'KLMNP',
          array:[
            {
              name:'K',
              array:['昆明','昆山','开封']
            },
            {
              name:'L',
              array:['兰州','临沂','洛阳','连云港','聊城','临汾','六安','娄底','漯河']
            },
            {
              name:'M',
              array:['马鞍山','昆山','开封']
            },
            {
              name:'N',
              array:['南京','宁波','南昌','南通','南宁','南阳']
            },
            {
              name:'P',
              array:['平顶山','濮阳']
            }
          ]
        },
         {
          name:'QRSTW',
          array:[
            {
              name:'Q',
              array:['青岛','衢州']
            },
            {
              name:'R',
              array:['日照']
            },
            {
              name:'S',
              array:['上海','深圳','沈阳','石家庄','苏州','绍兴','商丘','上饶','邵阳','十堰','随州','宿迁']
            },
            {
              name:'T',
              array:['天津','太原','泰州','泰安']
            },
            {
              name:'W',
              array:['武汉','无锡','温州','芜湖','潍坊','威海','渭南']
            }
          ]
        },
         {
          name:'XYZ',
          array:[
            {
              name:'X',
              array:['西安','徐州','襄樊','湘潭','咸阳','西宁','咸宁','孝感','新乡','信阳','许昌','宣城']
            },
            {
              name:'Y',
              array:['烟台','扬州','宜昌','盐城','义乌','银川','宜春','益阳','永州','岳阳','运城']
            },
            {
              name:'Z',
              array:['郑州','镇江','株洲','张家港','淄博','枣庄','周口','驻马店']
            }
          ]
        }
      ]
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        if (newVal.meta.index || newVal.meta.index == '0') {
          // if (newVal.meta.index == '2' || newVal.meta.index == '3' || newVal.meta.index == '4') {
          //   this.activeIndex = this.navList[newVal.meta.index].children[newVal.query.type - 1].index
          // } else {
          //   this.activeIndex = newVal.meta.index
          // }
          this.activeIndex = newVal.meta.index
        }
        //
        this.urlShow = newVal.path == "/login" ? false : true;
      },
      //  immediate为true时则立即触发回调函数；如果为false，则和上面的例子一样，不会立即执行回调。
      immediate: true,
      deep: true,
    },
  },
  methods: {
    changeCity (name) {
      this.cityList.forEach(item=>{
        item.array.forEach(em=>{
          em.array.forEach(data=>{
            if (name == data) {
              console.log('这里1111111111111');
            }
          })
        })
      })
    },
    //选择城市
    selectCity (name) {
      this.isCity = false
      this.$store.commit('SET_CITY',{city:name})
    },
    handleChangeCity () {
      let data = []
      for (let i = 0; i < this.areaList.length; i++) {
        data.push(this.CodeToText[this.areaList[i]]);
      }
      store.commit('SET_REGION',data.join('/'))
    },
    //退出登录
    quitLogin (e) {
      if (e == 0) {
        this.$router.push({path:'/personage/aboutSee'})
      } else {
        this.$store.commit("REMOVE_TOKEN")
        this.$router.push({path:'/login'})
      }
    },
    handleSelect(key, keyPath) {
      var url = null
      if (keyPath.length == 2) {
        url =  this.navList[keyPath[0]].children[keyPath[1].split('-')[1] - 1].url
      } else {
        url = this.navList[key].url
      }
      if (url == this.$route.fullPath) return
			
			
      this.$router.replace({ path:url })
      if (url == '/housing?type=2') {
        this.getfangCheckLog()
      }
      if (url == '/passenger?type=1') {
        this.$axios.clientCheckLog({client_type:1})
        this.$axios.clientCheckLog({client_type:2})
      }
    },
    getfangCheckLog () {
      this.$axios.fangCheckLog({
        type:this.$route.query.type
      })
    }
  }
}
</script>
<style lang="less" scoped>
.el-menu.el-menu--horizontal {
  border-bottom: none;
  display: flex;
  justify-content: space-between;
}
.el-menu-item {
  font-size: 18px;
}
/deep/ .el-submenu__title {
  font-size: 18px;
}
/deep/ .el-dropdown-item {
  text-align: center;
}
.navigation_top_main {
  height: 48px;
  position: relative;
  .navigation_city {
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      &:last-child {
        width: 14px;
        height: 8px;
      }
    }
    span {
      color: #666666;
      font-size: 14px;
      margin: 0 10px 0 5px;
    }
  }
  .navigation_login_head {
    cursor: pointer;
    img {
      &:first-child {
        width: 28px;
        height: 28px;
        border-radius: 50%;
      }
      &:last-child {
        width: 10px;
        height: 6px;
        margin-left: 12px;
      }
    }
  }
  .orientation {
    position: absolute;
    top: 48px;
    left: 0;
    width: 500px;
    z-index: 2022;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    .orientation_head {
      .orientation_head_txt {
        color: #999999;
        font-size: 14px;

      }
      .orientation_head_list {
        flex-wrap: wrap;
        li {
          cursor: pointer;
          color: #666666;
          font-size: 14px;
          margin-top: 12px;
          margin-right: 30px;
        }
      }
    }
    .search {
      height: 36px;

      border: 1px solid #EBEBEB;
      /deep/ .el-input__inner {
        border: none;
        height: 34px;
      }
      img {
        width: 22px;
        height: 22px;
      }
    }
    .city_head {
      padding: 10px 0;
      border-bottom:1px solid #EBEBEB;
      .city_head_li {
        width: 64px;
        text-align: center;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
      .active {
        position: relative;
        &::before {
          bottom: -10px;
          content: '';
          width: 64px;
          height: 2px;
          left: 0;
          background: #3273F6;
          position: absolute;
        }
      }
    }
    .orientation_main_list {
      padding-top: 20px;
      li {
        display: flex;
        .orientation_main_city {
          display: flex;
          .orientation_main_city_li {
            color: #666666;
            font-size: 14px;
            margin-left: 20px;
            margin-bottom: 20px;
            cursor: pointer;
          }
          .active {
            color: #3273F6;
          }
        }
      }
    }
  }
}
.navigation_logo {
  width: 268px;
  height: 74px;
}
</style>